import { ProjectType, SubscriptionTier, UserRole } from "@prisma/client";
import { oneGbInBytes } from "../shared/constants";

export const minLength = 128;
const freeMaxLength = 512;
const creatorMaxLength = 1024;
export const proMaxLength = 1472;
const businessMaxLength = 4096;
const staffMaxLength = 5120;

/**
 * Stripe lookup key for each price. this allows us to pick the correct pricing data such as frames to credit on purchase.
 *
 * It also allow us to control the pricing update, if we want to update the price of a plan we can create a new price and
 * update the lookup key here to point to the new price. That's why we have the date in the key, so we can have multiple
 * prices for the same plan.
 */
const stripeLookupKeys = [
  "free",
  // frame pack keys
  "frame_pack_20231211",

  // creator keys
  "creator_monthly_20231211",
  "creator_yearly_20231211",
  "creator_monthly_20231219",
  "creator_yearly_20231219",
  "creator_monthly_20240422",
  "creator_yearly_20240422",

  // pro keys
  "pro_monthly_20231211",
  "pro_yearly_20231211",
  "pro_monthly_20231219",
  "pro_yearly_20231219",
  "pro_monthly_20240422",
  "pro_yearly_20240422",

  // business keys
  "business_monthly_20240422",
  "business_yearly_20240422",
] as const;

export type StripeLookupKey = (typeof stripeLookupKeys)[number];

export type PlanType = "pro" | "creator" | "business";
export type IntervalType = "monthly" | "yearly";

/**
 * a helper function just to ensure type safety on the lookup keys. This avoids having to import
 * the stripeLookupKeys and do a lookup
 */
export const planToStripeLookupKey = <T extends StripeLookupKey>(key: T): T => {
  return key;
};

type PlanInfo = {
  isPaid: boolean;
  maxLength: { [key in ProjectType]: number };
  maxFileSize: number;
  plan: PlanType | "free" | "social media partner" | "staff";
};

type Plan = {
  duration: IntervalType | "none";
  plan: string;
  frames: number;
} & PlanInfo;

const freeMaxLengthByType = {
  ANIMATION: freeMaxLength,
  AUTO_SUBTITLE: freeMaxLength,
  VIDEO_TO_VIDEO: freeMaxLength,
  IMAGE_TO_VIDEO: freeMaxLength,
  TEXT_TO_VIDEO: freeMaxLength,
  FACE_SWAP: freeMaxLength,
  LIP_SYNC: freeMaxLength,
};
const creatorMaxLengthByType = {
  ANIMATION: creatorMaxLength,
  AUTO_SUBTITLE: creatorMaxLength,
  VIDEO_TO_VIDEO: creatorMaxLength,
  IMAGE_TO_VIDEO: creatorMaxLength,
  TEXT_TO_VIDEO: creatorMaxLength,
  FACE_SWAP: creatorMaxLength,
  LIP_SYNC: creatorMaxLength,
};
const proMaxLengthByType = {
  ANIMATION: proMaxLength,
  AUTO_SUBTITLE: proMaxLength,
  VIDEO_TO_VIDEO: proMaxLength,
  IMAGE_TO_VIDEO: proMaxLength,
  TEXT_TO_VIDEO: proMaxLength,
  FACE_SWAP: proMaxLength,
  LIP_SYNC: proMaxLength,
};
// business only support max res for face swap and lip sync for now
const businessMaxLengthByType = {
  ANIMATION: proMaxLength,
  AUTO_SUBTITLE: proMaxLength,
  VIDEO_TO_VIDEO: proMaxLength,
  IMAGE_TO_VIDEO: proMaxLength,
  TEXT_TO_VIDEO: proMaxLength,
  FACE_SWAP: businessMaxLength,
  LIP_SYNC: businessMaxLength,
};
const staffMaxLengthByType = {
  ANIMATION: staffMaxLength,
  AUTO_SUBTITLE: staffMaxLength,
  VIDEO_TO_VIDEO: staffMaxLength,
  IMAGE_TO_VIDEO: staffMaxLength,
  TEXT_TO_VIDEO: staffMaxLength,
  FACE_SWAP: staffMaxLength,
  LIP_SYNC: staffMaxLength,
};

const planLimits: { [key in SubscriptionTier]: PlanInfo } = {
  business: {
    isPaid: true,
    maxLength: businessMaxLengthByType,
    maxFileSize: oneGbInBytes * 3,
    plan: "business",
  },
  pro: {
    isPaid: true,
    maxLength: proMaxLengthByType,
    maxFileSize: oneGbInBytes * 2,
    plan: "pro",
  },
  creator: {
    isPaid: true,
    maxLength: creatorMaxLengthByType,
    maxFileSize: oneGbInBytes,
    plan: "creator",
  },
  free: {
    isPaid: false,
    maxLength: freeMaxLengthByType,
    maxFileSize: (oneGbInBytes / 1024) * 100,
    plan: "free",
  },
};

export const getPlanLimits = (tier: SubscriptionTier, userRole?: UserRole): PlanInfo => {
  if (userRole === "socialMediaPartner") {
    return {
      isPaid: true,
      maxLength: proMaxLengthByType,
      maxFileSize: oneGbInBytes,
      plan: "social media partner",
    };
  }

  if (userRole === "vip") {
    return {
      isPaid: true,
      maxLength: proMaxLengthByType,
      maxFileSize: oneGbInBytes,
      plan: "pro",
    };
  }

  if (userRole === "staff") {
    return {
      isPaid: true,
      maxLength: staffMaxLengthByType,
      maxFileSize: oneGbInBytes * 100,
      plan: "staff",
    };
  }

  return planLimits[tier];
};

export const creatorMonthlyFrames = 10000;
export const proMonthlyFrames = 50000;
export const businessMonthlyFrames = 250000;

const creatorMonthly: Plan = {
  duration: "monthly",
  frames: creatorMonthlyFrames,
  ...planLimits.creator,
};
const creatorYearly: Plan = {
  duration: "yearly",
  frames: creatorMonthlyFrames * 12,
  ...planLimits.creator,
};
const proMonthly: Plan = {
  duration: "monthly",
  frames: proMonthlyFrames,
  ...planLimits.pro,
};
const proYearly: Plan = {
  duration: "yearly",
  frames: proMonthlyFrames * 12,
  ...planLimits.pro,
};
const businessMonthly: Plan = {
  duration: "monthly",
  frames: businessMonthlyFrames,
  ...planLimits.business,
};
const businessYearly: Plan = {
  duration: "yearly",
  frames: businessMonthlyFrames * 12,
  ...planLimits.business,
};

const stripeLookupKeyToPlanMap: { [key in StripeLookupKey]: Plan } = {
  // creator keys
  creator_monthly_20231211: creatorMonthly,
  creator_yearly_20231211: creatorYearly,
  creator_monthly_20231219: creatorMonthly,
  creator_yearly_20231219: creatorYearly,
  creator_monthly_20240422: creatorMonthly,
  creator_yearly_20240422: creatorYearly,

  // pro keys
  pro_monthly_20231211: proMonthly,
  pro_yearly_20231211: proYearly,
  pro_monthly_20231219: proMonthly,
  pro_yearly_20231219: proYearly,
  pro_monthly_20240422: proMonthly,
  pro_yearly_20240422: proYearly,

  // business keys
  business_monthly_20240422: businessMonthly,
  business_yearly_20240422: businessYearly,

  free: {
    duration: "none",
    frames: 0,
    ...planLimits.free,
  },
  frame_pack_20231211: {
    isPaid: true,
    duration: "none",
    plan: "free",
    frames: 1000,
    maxLength: freeMaxLengthByType,
    maxFileSize: (oneGbInBytes / 1024) * 100,
  },
};

export const stripeLookupKeyToPlan = (key: string): Plan => {
  // TODO[custom plan]: figure out how to handle custom plans. For now we just return pro monthly as a quick hack
  if (key === "pro_monthly_custom_20240309") {
    return {
      duration: "monthly",
      frames: proMonthlyFrames,
      ...planLimits.pro,
    };
  }

  if (Object.keys(stripeLookupKeyToPlanMap).includes(key) === false) {
    // TODO: do we want to throw an error? or just return free?
    throw new Error(`Invalid stripe lookup key: ${key}`);
  }

  // safe to cast here because we check if the key exists in the map
  return stripeLookupKeyToPlanMap[key as StripeLookupKey];
};
